body {
  background-color: black;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  color: white;
  font-family: 'Inter', sans-serif !important
}

body::-webkit-scrollbar {
  display: none;
}

p {
  font-size: 15px;
}

h2 {
  color: white;
  font-weight: 600;
  letter-spacing: -2px;
}

h3 {
  color: white;
  font-weight: 600;
}

h4 {
  color: white;
  font-weight: 600;
}

.headerDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerSec {
  padding: 0.5% 10%;
  position: sticky;
  top: 0;
  background-color: black !important;
  z-index: 99999;
}

.header {
  background-color: black !important;
}

.nav-link {
  color: white !important;
  font-weight: 500;
  font-size: 17px !important;
}

.nav-item {
  margin: 0 3% !important;
  border-bottom: 2px solid transparent;
}

.nav-item:hover {
  border-bottom: 2px solid #93f059;
}

.headerItems {
  list-style: none;
  padding: 0;
  position: relative;
  z-index: 9999 !important;
}

.heroSec {
  width: 100%;
  margin: auto 0 !important;
  height: 90vh;
  z-index: 1 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.heroDiv {
  padding: 0 15%;
}

.heroHeading {
  font-size: 50px;
  font-weight: 600 !important;
  width: 70%;
  margin: auto;
  text-align: center;
  position: relative;
}

.gradientText {
  background: linear-gradient(to right, white, white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  background-clip: text;
  color: transparent
}

.heroPara {
  font-weight: 500;
  color: white;
  opacity: 80%;
  width: 60%;
  margin: 2% auto;
  text-align: center;
  position: relative;
}

.csBtn {
  background-color: #93f059;
  color: white;
  border-radius: 5px;
  padding: 0.8% 1.8%;
  position: relative;
  font-weight: 500;
  border: none;
}

.csBtn:hover {
  background-color: #77cf40;
}

.servicesSec {
  width: 100%;
  margin: auto;
  padding: 5% 20%;
  justify-content: center;
}

.servicesDiv {
  background-color: #18181E;
  border-radius: 10px;
  padding: 2.5%;
  position: relative;
  z-index: 2;
  flex-basis: calc(50% - 30px);
  margin-right: 30px;
}

.servicesDiv:last-child {
  margin-right: 0;
}


.servicesParentDiv {
  margin-bottom: 4%;
}

.csHeading {
  font-size: 40px;
  margin-bottom: 8%;
  width: 50%;
  text-align: center;
}

.icon {
  padding: 2%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
}


.icon-1,
.icon-2,
.icon-3,
.icon-4,
.icon-5,
.icon-6 {
  background-color: #93f059 !important;
}

i {
  color: white !important;
  font-size: 22px;
}

.ctaSec {
  width: 100%;
  margin: auto 0 !important;
  padding: 3% 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
}

.ctaDiv {
  background-color: #18181E;
  border-radius: 10px;
  padding: 5%;
  position: relative;
  z-index: 9999;
}

.ctaHeading {
  width: 70%;
  text-align: left;
  font-size: 40px;
}

.ctaPara {
  width: 60%;
  text-align: left;
  opacity: 80%;
  font-weight: 500;
}

.featuresSec {
  background-color: #18181E;
  width: 100%;
  margin: auto;
  padding: 8% 15%;
  margin: 0 !important;
  justify-content: center;
}

.featuresDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1%;
  margin-bottom: 3%;
}


.ctaBtn {
  background-color: #93f059 !important;
}

.ctaBtn:hover {
  background-color: #77CF40 !important;
}

.featuresIconDiv {
  width: 50px !important;
  height: 50px !important;
}

.testiSec {
  width: 100%;
  margin: auto 0 !important;
  padding: 8% 10%;
  justify-content: left;
}

.testiDiv {
  margin: auto;
  border-radius: 10px;
  height: 330px;
  padding: 7%;
  margin-bottom: 5%;
  position: relative;
  z-index: 2;
}

.testiImg {
  border-radius: 50%;
  width: 15%;
  margin-right: 3%;
}

.testiName {
  font-family: bold;
  font-size: 17px;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.arrow-div {
  display: flex;
  justify-content: center;
}

.testi-icon-arrow {
  font-size: 700px;
  position: absolute;
  opacity: 12%;
  text-align: center;
  transform: rotate(-15deg);
}

.testiImg {
  border-radius: 50%;
  width: 12%;
  margin-right: 3%;
}

.testi-icon {
  padding: 1.5%;
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testiBtn {
  background-color: #93f059;
  color: white;
  border-radius: 5px;
  padding: 1.5% 3%;
  border: none;
  position: relative;
  z-index: 2;
  width: fit-content;
  margin: auto;
  margin-top: 3%;
  font-weight: 500;
}

.testiBtn:hover {
  background-color: #77cf40 !important;
}


.heroForm {
  width: 70%;
  margin: auto;
  background-color: #18181E;
  padding: 5%;
  border-radius: 10px;
  position: relative;
  z-index: 999;
}

input {
  width: 100%;
  border: none;
  border-bottom: 2px solid rgb(105, 105, 105);
  background-color: transparent;
  padding: 3%;
  color: white;
}

.submitBtn {
  width: 100%;
  background-color: #93f059;
  color: white;
  border-radius: 5px;
  font-weight: 500;
  padding: 3.5% 0 !important;
  border: none;
}

.footerSec {
  width: 100%;
  margin: auto 0 !important;
  padding: 5% 15%;
}

.footerItems {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 5%;
  align-items: center;
}


.footerItems a {
  text-decoration: none !important;
  color: gray;
}

.footerItem {
  padding: 0;
  margin: 0;
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 2%;
  opacity: 80%;
}

.footerItem:hover {
  opacity: 100%;
  cursor: pointer;
}

.footerSocialDiv {
  text-align: left;
}

.footerIcons {
  font-size: 20px;
  margin-right: 5%;
  color: white !important;
  opacity: 100%;
  margin-top: 5%;
}

.footerIcons:hover {
  cursor: pointer;
}

#mobile-footerSec-1 {
  display: none;
}

.footerConatctIcons {
  margin-right: 3%;
  font-size: 17px !important;
}

.hrFooter {
  margin: 3% 0;
}

.footerForm {
  background-color: transparent;
}

.newBtn {
  background-color: #93f059;
  color: white;
  border-radius: 5px;
  border: none !important;
  height: 100% !important;
}

.social-icons {
  text-align: center;
}

a {
  text-decoration: none;
  color: white;
}

.dashed-arrow {
  transform: rotate(15deg);
  top: 45% !important;
  left: 20%;
}

.curve-arrow {
  width: 15% !important;
  transform: rotate(15deg);
  right: 7%;
}

.hero-arrow {
  width: 15% !important;
  transform: rotate(15deg);
  right: 12%;
  top: 45%;
}

.down-arrow {
  transform: rotate(45deg);
  right: 5%;
}

.communityDiv {
  border: 2px solid #46166d;
  background-color: #1d072f9c;
  border-radius: 30px;
  padding: 8% 6%;
  text-align: center;
}

.communityBtn {
  margin-top: 5%;
  padding: 3% 9% !important;
  display: flex;
  justify-content: center;
}

.headerBtn {
  background-color: #105cff;
  color: white;
  font-weight: 500;
  border-radius: 50px;
  border: none;
  padding: 2% 5% !important;
}

.footerContactIcons {
  font-size: 18px;
  margin-right: 2%;
  opacity: 70%;
}

.brandingWrapper {
  display: none !important;
}

.processSec {
  width: 100%;
  margin: auto 0 !important;
  padding: 6% 15%;
  display: flex;
  justify-content: center;
}

.processDiv {
  flex-basis: calc(25% - 15px);
  margin-right: 15px;
  padding: 0;
  border: 2px solid transparent;
}

.processDiv:last-child {
  margin-right: 0;
}

.processInnerSec {
  padding: 0;
  background-color: black;
  display: flex;
  justify-content: center;
  z-index: 9999;
  margin-top: 5%;
  border-radius: 10px;
}

.processHeading {
  font-size: 40px;
  margin-bottom: 3%;
  width: 50%;
  text-align: center;
  position: relative;
  z-index: 9999;
}

.portSec {
  width: 100%;
  margin: auto;
  padding: 3% 15% 8%;
}

.portDiv {
  padding: 3%;
}

@media (max-width: 900px) {
  h2 {
    font-weight: 600;
    letter-spacing: 0px;
  }

  .headerSec {
    padding: 0.5% 0;
  }

  .headerDiv {
    display: block;
  }

  .header {
    display: block;
    background-color: black !important;
    padding: 5% 3% !important;
    position: relative;
    z-index: 9999;
  }

  .heroSec {
    height: auto;
    padding: 20% 3%;
  }

  .heroHeading {
    width: 100%;
    font-size: 35px;
    font-weight: 700 !important;
  }

  .heroDiv {
    padding: 0 3%;
  }

  .heroPara {
    width: 100%;
    margin: 6% 0;
  }

  .csBtn {
    padding: 3.5% 8%;
  }

  .formHeading {
    margin: 0;
    margin-top: 10%;
    font-size: 30px;
  }

  form {
    width: 100%;
    padding: 10% 3%;
  }

  .langSec {
    padding: 0 3%;
  }

  .langLogo {
    opacity: 50%;
    margin: 4% 2%;
    width: 15%;
  }

  .servicesSec {
    padding: 15% 3% 5%;
    display: flex;
    justify-content: center;
  }

  .servicesDiv {
    padding: 7%;
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 10%;
  }

  .servicesParentDiv {
    margin-bottom: 0;
  }

  .csHeading {
    font-size: 30px;
    margin: 10% 0;
    width: 100%;
    margin-bottom: 0;
  }

  .featuresSec {
    padding: 10% 3%;
  }

  .featuresDiv {
    padding: 2%;
    margin-bottom: 10%;
  }

  .subFeaturesDiv {
    padding: 6%;
  }

  .testiSec {
    padding: 10% 3%;
  }

  .testiDiv {
    margin: 0;
    padding: 5%;
    height: auto;
    margin-bottom: 8%;
  }

  .testiBtn {
    padding: 3.5% 8%;
    margin-top: 3%;
  }

  .ctaSec {
    padding: 10% 3% 0;
    margin-bottom: 10%;
  }

  .ctaDiv {
    padding: 13% 7%;
  }

  .ctaHeading {
    width: 100%;
    font-size: 30px;
  }

  .ctaPara {
    width: 100%;
  }

  .footerSec {
    padding: 5% 3%;
  }

  .footerItem {
    font-size: 16px;
  }

  .footerDiv {
    margin: 5% 0;
  }

  .footerPara {
    font-size: 14px;
  }

  .footer-logo {
    width: 50%;
  }

  .testi-icon-arrow {
    display: none !important;
  }

  .down-arrow {
    display: none !important;
  }

  .featuresDiv-2 {
    order: 1;
    margin-bottom: 8%;
  }

  .featuresDiv-1 {
    order: 2;
  }

  .processSec {
    padding: 3% 3%;
    display: flex;
    justify-content: center;
  }

  .processDiv {
    flex-basis: 100%;
    margin-right: 0;
    padding: 3%;
  }

  .processInnerSec {
    padding: 5%;
  }

  .processHeading {
    font-size: 30px;
    margin: 10% 0 5%;
    width: 100%;
  }

  .arrow-div {
    display: none;
  }

  .semiCircle-form,
  .semiCircle-process,
  .semiCircle-cta,
  .semiCircle-features-2,
  .semiCircle-features,
  .semiCircle-services-2,
  .semiCircle-services,
  .semiCircle-light,
  .semiCircle-footer,
  .semiCircle-footer-2 {
    display: none;
  }

  #mobile-footerSec-1 {
    display: flex;
  }

  #desktop-footerSec-1 {
    display: none;
  }

  .footerSec {
    margin-top: 15%;
    padding: 10% 3%;
  }

  .footerItems {
    margin: 15% 0;
  }

  .footerSec-2 {
    width: 100%;
    margin: auto;
  }

  .hrFooter {
    margin: 10% 0;
  }

  .newBtnDiv {
    margin-top: 5%;
  }

  .portSec {
    padding: 3% 3% 15% 3%;
  }

  .portDiv {
    padding: 0;
    margin-top: 15%;
  }
}